.App {
  font-family: 'Inter-Light';
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.not-found {
  padding-top: 5%;
}

.not-found p {
  font-size: 5vh;
  line-height: normal;
}

.center {
  text-align: center;
}

.fit-height {
  max-height: 100vh;
}

.underline {
  text-decoration: underline;
}

.active-sub.subarticle {
  text-decoration: underline;
  font-family: 'Inter-Bold';
}

.black-theme {
  color: black;
  background-color: white;
}

.blue-theme {
  color: #0D243F;
  background-color: #8BD0F3;
}

.blue-theme .active-sub.subarticle {
  color: #8BD0F3;
  background-color: #0D243F;
}

.blue-theme-transition {
  color: #0D243F;
  background-color: white;
}

.blue-theme-transition .active-sub.subarticle {
  color: #0D243F;
  background-color: white;
}

.dark-blue-theme {
  color: white;
  background-color: #0D243F;
}

.dark-blue-theme .active-sub.subarticle {
  color: #0D243F;
  background-color: white;
}

.dark-blue-transition-theme {
  color: #0D243F;
  background-color: white;
}

.dark-blue-transition-theme .active-sub.subarticle {
  color: white;
  background-color:#0D243F;
}

.red-theme {
  color: #D72344;
  background-color: white;
}

.red-theme .active-sub.subarticle {
  color: white;
  background-color: #D72344;
}

.red-transition-theme {
  color: white;
  background-color: #D72344;
}

.purple-theme {
  color: #380343;
  background-color: #F1CEE2;
}

.purple-theme .active-sub.subarticle {
  color: #F1CEE2;
  background-color: #380343;
}

.purple-transition-theme {
  color: #F1CEE2;
  background-color: #380343;
}

.active-sub.subarticle:hover {
  color: inherit;
  background: none;
}

.active-sub.subarticle a {
  color: inherit;
}

.active-sub.subarticle a:hover {
  color: inherit;
}

.headroom-wrapper {
  height: max-content !important;
}

.nav-item {
  font-family: 'Inter-Regular';
  align-self: flex-start;
  font-size: 3.5vh;
  padding-bottom: 0;
  line-height: normal;
  text-decoration-line: underline;
}

@media (max-width: 768px) {
  .nav-item {
    font-size: 2.5vh;
  }
}

@media (max-width: 576px) {
  .nav {
    text-align: end !important;
  }

  #brand-container {
    overflow: hidden;
  }

  #brand-img {
    margin-left: 22%;
  }
}

.img-home {
  margin-top: -10%;
}

.arrow-btn {
  cursor: pointer;
}

.section-container {
  height: 100vh;
}

.section {
  height: 100vh;
}

.progress-dots {
  padding-top: 30%;
  padding-bottom: 10%;
  z-index: 1;
}

p {
  font-size: 2.5vh;
  line-height: 3.5vh;
}

.learn-more {
  font-size: 2.5vh;
}

h1 {
  font-family: 'Tiny';
}

.section-title {
  font-size: 10vh;
  line-height: normal !important;
}

.section-img {
  padding-top: 10%;
}

.special-title {
  width: fit-content;
  text-align: end;
}

.special-title h1 {
  margin-bottom: 0;
}

.special-title p {
  font-style: italic;
  font-size: 4.5vh;
  margin-top: 0;
  margin-bottom: 10%;
}

.special-section-title h1 {
  line-height: 50px;
  margin-bottom: 0;
}

.special-section-title p {
  font-style: italic;
  font-size: 30px;
  margin-top: 0;
  margin-bottom: 10%;
}

.main-text {
  font-size: 4vh;
  line-height: 5vh;
}

.em-text {
  font-family: 'Inter-Regular';
  font-size: 5vh;
  line-height: 6vh;
}

.supplemental-text {
  font-family: 'Inter-Regular';
  font-size: 3vh;
  line-height: 4vh;
}

.scroll-section {
  height: 200vh;
}

.inner-section {
  height: 100vh;
}

.bubble-text {
  font-family: 'Tiny';
  font-size: 20vh;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.img-overlay {
  position: relative;
}

@keyframes rotation {
  from {
    transform: rotate(359deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@keyframes break {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(13deg) translateX(10%);
  }
}

@keyframes show-break {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes lock {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-50%);
  }
}

@keyframes drop {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(27%);
  }
}

@keyframes tilt-right {
  from {
    transform: rotate(20deg);
  }
  to {
    transform: rotate(-10deg);
  }
}

@keyframes tilt-left {
  from {
    transform: scaleX(-1) rotate(20deg);
  }
  to {
    transform: scaleX(-1) rotate(-10deg);
  }
}

@keyframes spill {
  from {
    bottom: -34%;
    left: 33%;
    height: 75%;
    width: 75%;
  }
  to {
    bottom: -40%;
    left: 27.5%;
    height: 90%;
    width: 90%;
  }
}

#spiral {
  position: absolute;
  display: block;
  top: 19%;
  bottom: 0;
  left: 35%;
  right: 0;
  height: 21%;
  width: 21%;
  animation: rotation 5s infinite linear;
}

#chain-1 {
  margin-left: 25%;
}

#chain-2 {
  position: absolute;
  display: block;
  top: -5.5%;
  bottom: 0;
  left: 58%;
  right: 0;
  height: 100%;
  width: 100%;
}

#chain-3 {
  position: absolute;
  display: block;
  top: 6%;
  bottom: 0;
  left: 45%;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
}

#lock-top {
  position: absolute;
  display: block;
  left: 2.5%;
  top: 10%;
  z-index: -1;
  width: 95%;
}

.ball {
  position: absolute;
  display: block;
  right: -5%;
  top: -15%;
  width: 100%;
}

.ball-shadow {
  padding-top: 60%;
}

.foot {
  width: 25%;
}

#foot-1 {
  padding-top: 20%;
  transform: rotate(25deg);
}

#foot-2 {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1) rotate(30deg);
}

#foot-3 {
  padding-top: 40%;
  transform: rotate(20deg);
}

#foot-4 {
  padding-top: 10%;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1) rotate(20deg);
}

#spill {
  position: absolute;
  display: block;
  bottom: -34%;
  left: 33%;
  height: 75%;
  width: 75%;
}

.break {
  animation: break 1s 1 linear forwards;
  animation-delay: 1s;
}

.show-break {
  animation: show-break 1s 1 step-end forwards;
  animation-delay: 1s;
}

.lock {
  animation: lock 2s 1 linear forwards;
  animation-delay: 1s;
}

.drop-1 {
  animation: drop 1s 1 linear forwards;
  animation-delay: 1s;
}

.drop-2 {
  animation: drop 1s 1 linear forwards;
  animation-delay: 2.5s;
}

.tilt-right {
  animation: tilt-right 0.6s 1 linear;
  animation-delay: 1s;
  animation-direction: alternate;
  animation-iteration-count: 2;
}

.tilt-left {
  animation: tilt-left 0.6s 1 linear;
  animation-delay: 1s;
  animation-direction: alternate;
  animation-iteration-count: 2;
}

.spill {
  animation: spill 2s 1 linear forwards;
  animation-delay: 1s;
}

.core-principles {
  text-transform: uppercase;
  font-size: 3vh;
  line-height: normal;
  font-family: 'Inter-Regular';
  margin: 0;
}

.main-article-button.btn {
  padding: 5vh;
  border: 3px solid transparent;
  border-radius: 150px;
  width: 100%;
  font-family: 'Inter-Bold';
  font-size: 3vh;
}

.blue-theme-transition .main-article-button {
  background: #0D243F;
  color: white;
}

.dark-blue-theme .main-article-button {
  background: white;
  color: #0D243F;
}

.red-theme .main-article-button {
  background: #D72344;
  color: white;
}

.purple-theme .main-article-button {
  color: #F1CEE2;
  background-color: #380343;
}

.blue-theme-transition .main-article-button:hover {
  border-color: #0D243F;
  color: #0D243F;
  background-color: white;
}

.dark-blue-theme .main-article-button:hover {
  border-color: white;
  color: white;
  background: none;
}

.red-theme .main-article-button:hover {
  border-color: #D72344;
  color: #D72344;
  background: none;
}

.purple-theme .main-article-button:hover {
  border-color: #380343;
  color: #380343;
  background: none;
}

#weber {
  color: inherit;
}

#weber:hover {
  font-weight: bolder;
}

#support-buttons {
  padding-top: 20%;
  padding-bottom: 10%;
}

.article-footer {
  margin-top: 20vh;
  margin-bottom: 5vh;
}

.references {
  padding-top: 20vh;
  border-top-left-radius: 10vw;
  border-top-right-radius: 10vw;
  border: 3px solid;
  word-wrap: break-word;
}

.references h1 {
  font-family: 'Tiny';
  font-size: 11vh;
}

.references .blue-theme {
  border-color: #0D243F;
}

.references .dark-blue-transition-theme {
  border-color: #0D243F;
}

.references a {
  color: inherit;
}

ol {
  font-family: 'Inter-Regular';
  counter-reset: list;
}

li {
  padding-bottom: 3vh;
}

ol > li {
    list-style: none;
}

ol > li:before {
    content: "[" counter(list) "] ";
    counter-increment: list;
}

#up-arrow {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  margin-top: -15%;
}

.about-padded {
  padding-top: 5%;
}

.about-double-padded {
  padding-top: 5%;
  padding-bottom: 5%;
}

.mission {
  font-family: 'Inter-Regular';
  text-transform: uppercase;
  font-size: 1.2em;
  line-height: normal;
}

.mission-right {
  text-align: end;
}

.mission-md {
  font-size: 1.5em;
  line-height: normal;
}

.mission-xl {
  font-size: 2em;
  line-height: normal;
}

.staff-text {
  font-family: 'Inter-Regular';
  font-size: 3vh;
  line-height: normal;
}

.contact-text {
  font-family: 'Inter-Regular';
  font-size: 3.5vh;
  line-height: normal;
  word-wrap: break-word;
}

.contact-text a {
  color: black;
  text-decoration: dotted underline;
}

#font-attr {
  font-size: 5.25vh;
  line-height: normal;
  font-family: 'Inter-Regular';
  padding-bottom: 3%;
}

@media (max-width: 576px) {
  #font-attr {
    font-size: 3.75vh;
  }
}

#tiny-attr {
  font-size: 6vh;
  line-height: normal;
  font-family: 'Tiny';
}

@media (max-width: 576px) {
  #tiny-attr {
    font-size: 4.5vh;
  }
}

#inter-attr {
  font-size: 3.75vh;
  line-height: normal;
  font-family: 'Inter-Regular';
}

@media (max-width: 576px) {
  #inter-attr {
    font-size: 2.75vh;
  }
}

.font-link a {
  color: black;
  text-decoration-style: dotted;
}

.ref-number {
  cursor: pointer;
  font-size: 3vh;
}

.tooltip-inner {
  max-width: 30vw !important;
  text-align: left !important;
}

@media (max-width: 768px) {
  .tooltip-inner {
    max-width: 70vw !important;
    text-align: left !important;
  }
}

@media (max-width: 576px) {
  .tooltip-inner {
    max-width: 90vw !important;
    text-align: left !important;
  }
}

#funding-box {
  border: 3px solid black;
  border-radius: 4vw;
  text-align: center;
}

#funding-box p {
  font-family: 'Inter-Bold';
  font-size: 3.3vh;
  padding: 13%;
  margin: 0;
}

.thanked-names p {
  font-size: 6vh;
  font-family: 'Inter-Regular';
  padding-bottom: 2%;
}

@media (max-width: 768px) {
  .thanked-names p {
    font-size: 5vh;
  }
}

@media (max-width: 576px) {
  .thanked-names p {
    font-size: 3vh;
    line-height: 2vh;
  }
}

.pill-filler {
  border-radius: 2vh;
  border: 2px solid black;
  height: 3vh;
}

.rectangle-filler {
  border: 2px solid black;
  height: 3vh;
}

.term {
  cursor: pointer;
  padding-top: 30%;
}

#glossary-modal div {
  background-color: #8BD0F3;
  border-radius: 0;
  border: none;
  color: white;
}

#support-modal div {
  background-color: #246394;
  border-radius: 0;
  border: none;
  color: white;
}

#support-modal a {
  color: white;
  font-size: 3.5vh;
}

#support-modal p {
  line-height: normal;
}

#support-mobile .dropdown-toggle {
  color: #246394 !important;
  border: 2px solid #246394;
  font-family: 'Inter-Bold';
  background: none;
}

#support-mobile h2 {
  font-family: 'Tiny';
  font-size: 10vh;
  line-height: 8vh;
  color: #246394;
}

#support-mobile a {
  color: #246394;
  font-family: 'Inter-Regular';
}

.modal-header {
  font-family: 'Inter-Bold';
  text-transform: uppercase;
  padding-left: 4% !important;
  padding-top: 3% !important;
  position: relative;
}

.modal-title {
  font-size: 300%;
  border-bottom: 1px solid white !important;
  line-height: 1.2 !important;
}

@media (max-width: 768px) {
  .modal-title {
    font-size: 200%;
  }
}

.modal-header .close {
  border: none;
  background: none;
  font-size: 8vh;
  color: white;
  font-family: 'Inter-Light';
  padding: 0;

}

.modal-header .close span {
  position: absolute;
  right: 0.5%;
  top: 0%;
  line-height: 70%;
}

.modal-body {
  font-family: 'Inter-Regular';
  padding-left: 4% !important;
  padding-right: 10% !important;
  padding-bottom: 5% !important;
}

#glossary-modal .modal-body p {
  font-size: 3.5vh;
  line-height: normal;
  padding-bottom: 10% !important;
}

@media (max-width: 576px) {
  .modal-body p {
    font-size: 2.5vh !important;
    line-height: normal;
  }
}

#glossary-modal .modal-body .btn {
  font-size: 3vh;
  border-radius: 5vh;
  border: 2px solid white;
  padding-left: 3em;
  padding-right: 3em;
  padding-top: 2vh;
  padding-bottom: 2vh;
  background: none;
  font-family: 'Inter-Bold';
  float: right;
}

#glossary-modal .modal-body .btn:hover {
  background-color: white;
  border: 2px solid white;
  color: #8BD0F3;
}

.get-involved-header {
  font-size: 12vh;
  padding-top: 5%;
  text-align: center;
  line-height: 7vh;
  margin: 0;
  color: #246394;
}

.filter-btn {
  margin-right: 1.5%;
  margin-top: 5%;
  font-family: 'Inter-Bold';
  padding: 1.5vh 3.5vh;
}

.main-filter-btn {
  color: #D72344;
  background: none;
  border: 3px solid #D72344;
  border-radius: 0;
}

.main-filter-btn.filterActive {
  background: #D72344;
  color: white;
}

.secondary-filter-btn {
  color: #0D243F;
  background: none;
  border: 3px solid #0D243F;
  border-radius: 5vh;
}

.secondary-filter-btn.filterActive {
  background: #0D243F;
  color: white;
}

#resource-list {
  padding-top: 5%;
}

.card.resource-card {
  margin-bottom: 5%;
  padding-bottom: 10%;
  height: 85%;
  border: 3px solid transparent;
}

.resource-card:hover {
  border: 3px solid #0D243F;
}

.card.organization {
  background-color: #FC6E21;
}

.card.campaign {
  background-color: #8BD0F3;
}

.card.video {
  background-color: #9CE25E;
}

.card.book {
  background-color: #BF98FF;
}

.card.article {
  background-color: #FFC849;
}

.resource-card .card-title {
  color: #3A3A3A;
}

.resource-card .card-subtitle {
  color: white;
}

.resource-card .card-text {
  text-transform: none;
  font-family: 'Inter-Light';
  color: white;
  font-size: 2vh;
  line-height: normal;
  padding-top: 5%;
}

.resource-link {
  font-family: 'Inter-Bold';
  text-transform: uppercase;
  text-decoration: none;
}

#blog-feed {
  padding-top: 5%;
}

.blog-post {
  padding-bottom: 4%;
  margin-bottom: 4%;
  border-bottom: 1px solid #C4C4C4;
}

.blog-post p {
  margin-bottom: 3%;
  line-height: normal;
}

.blog-post img {
  padding-top: 20%;
}

.article-section {
  text-transform: uppercase;
  font-family: 'Inter-Regular';
  font-weight: bold;
  font-size: 2.5vh;
}

.article-post-date {
  text-transform: uppercase;
  font-family: 'Inter-Light';
  font-size: 1.75vh;
}

h5.article-title a {
  font-family: 'Inter-Regular';
  font-weight: bold;
  color: black;
  text-decoration: none;
  font-size: 4vh;
}

h5.article-title a:hover {
  color: #898989;
}

.article-description {
  font-family: 'Inter-Light';
  font-size: 2.25vh;
  padding-top: 2%;
}

.article-head {
  padding-top: 2%;
  padding-bottom: 5%;
}

p.article-title {
  font-family: 'Inter-Regular';
  font-weight: bold;
  font-size: 7vh;
  line-height: normal;
  padding-bottom: 4%;
}

@media (max-width: 576px) {
  p.article-title {
    font-size: 5.5vh;
    line-height: normal;
  }
}

.article-subheader {
  padding-bottom: 5%;
}

.article-subheader p {
  line-height: 50%;
}

.article-content p {
  font-family: 'Inter-Regular';
  font-size: 3vh;
  line-height: 4vh;
}

#map {
  height: 90vh;
  width: 100%; 
}