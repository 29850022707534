@font-face {
  font-family: 'Inter-Bold';
  src: local('Inter-Bold'), url('./fonts/Inter-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter-Medium';
  src: local('Inter-Medium'), url('./fonts/Inter-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter-Regular';
  src: local('Inter-Regular'), url('./fonts/Inter-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter-Light';
  src: local('Inter-Light'), url('./fonts/Inter-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Tiny';
  src: local('Tiny'), url('./fonts/TINY5x3-160.woff') format('truetype');
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
